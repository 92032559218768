import Demo5 from "../pages/demo-5";


const Home = () => {
  return (
    <div id="page" className="page-wrapper demo">
      <Demo5 />
    </div>
  );
};

export default Home;
